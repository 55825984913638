<template>
  <b-table
    :data="earnings"
    :striped="true"
    :hoverable="true"
    :loading="loading"
    class="mt-4 user-table"
  >
    <b-table-column field="created_at" label="Date" v-slot="props">
      <p>
        {{ props.row.created_at | dateStandard }}
      </p>
    </b-table-column>

    <b-table-column field="type" label="Type" v-slot="props">
      <p>
        <b-icon
          :icon="props.row.type === 1 ? 'plus-circle' : 'minus-circle'"
          :type="props.row.type === 1 ? 'is-success' : 'is-danger'"
          size="is-medium"
        />
      </p>
    </b-table-column>

    <b-table-column :visible="user === undefined" field="user_id" label="User" v-slot="props">
      <p v-if="props.row.user_id" @click="goToUser(props.row.user_id)" class="clickable-user">
        <strong>{{ props.row.user ? props.row.user.name : props.row.name }}</strong>
        <br />
        {{ props.row.user ? props.row.user.email : props.row.email }}
      </p>
    </b-table-column>

    <b-table-column field="monetti" label="Monetti Amount" v-slot="props">
      <p>{{ props.row.amount }}</p>
    </b-table-column>

    <b-table-column field="origin" label="Origin" v-slot="props">
      <p>{{ props.row.origin }}</p>
    </b-table-column>
  </b-table>

</template>

<script>
export default {
  name: 'EarningsList',
  props: {
    earnings: Array,
    loading: Boolean,
    user: Object,
  },
  methods: {
    goToUser(userId) {
      this.$router.push({ path: `/users/${userId}` });
    },
  },
};
</script>

<style>
.user-table {
  font-size: 12px;
}
.clickable-user {
  cursor: pointer;
  text-decoration: underline;
}
</style>
